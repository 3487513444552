.search_page {
  width: 100%;
  height: 100vh;
  position: relative;
  background: #fff;
  padding: 0 16px;
  overflow: hidden;
  overflow-y: scroll;

  .search {
    width: 100%;
    background: #fff;
    padding: 20px 0px;
    display: flex;
    align-items: center;

    > img {
      width: 11px;
      height: 11px;
      margin-right: 5px;
      cursor: pointer;
    }

    > button {
      width: 30px;
      outline: none;
      border: none;
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      color: #414a58;
      margin-left: 6px;
      background: #fff;
      cursor: pointer;
    }

    > div {
      flex: 1;
      height: 30px;
      background: #f2f3f5;
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 0 16px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      input {
        border: none;
        flex: 1;
        outline: none;
        height: 30px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #414a58;
        line-height: 16px;
        background: transparent;
        &::placeholder {
          color: #86909d;
        }
      }
      input[type="search"] {
        -webkit-appearance: none;
      }
    }

    > P {
      width: calc(100% - 52px);
      height: 30px;
      padding: 0;
      margin: 0;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .keywords {
    width: 100%;
    background: #fff;

    > p {
      height: 16px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #414a58;
      line-height: 16px;
      margin-bottom: 16px;
    }

    > ul {
      list-style: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        height: 26px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        background: #f2f3f5;
        border-radius: 26px;
        margin: 0 12px 12px 0;

        a {
          text-decoration: none;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #414a58;
          line-height: 16px;
          > img {
            width: 10px;
            height: 14px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .search_result {
    > ul {
      list-style: none;
      width: 100%;
      display: flex;
      align-items: baseline;
      background: #fff;
      gap: 28px;
      margin-bottom: 23px;

      li {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1d2129;
        line-height: 22px;
        border-bottom: solid 2px transparent;
        cursor: pointer;
      }

      .active {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2282ff;
        line-height: 25px;
        border-bottom: solid 2px #2282ff;
      }
    }
  }

  .result_box {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      height: 23px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1d2129;
      line-height: 23px;

      > div {
        display: flex;
        align-items: center;
        height: 18px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #86909d;
        line-height: 18px;
        cursor: pointer;

        span {
          margin: 0 4px;
          color: #2282ff;
        }
      }

      img {
        width: 10px;
        height: 10px;
        margin-left: 2px;
      }
    }

    ul {
      width: 100%;
      list-style: none;

      li {
        margin: 0 0 24px;
        display: block;
        width: 100%;
        height: 90px;
        box-shadow: none;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        position: relative;
        .cover {
          width: 160px;
          height: 90px;
          border-radius: 4px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center 0;
          border-radius: 4px;
          position: absolute;
        }
        .desc {
          max-width: 150px;
          margin-left: 16px;
          position: absolute;
          left: 160px;
          height: 100%;
          text-align: left;
          .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }
          .tt {
            height: 40px;
            margin: 0;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            color: #333;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .ct {
            display: flex;
            flex-direction: column-reverse;
            > div {
              display: flex;
              align-items: center;
            }
            .row {
              height: 22px;
              line-height: 22px;
              margin-bottom: 4px;
              .uv {
                color: #999;
                font-size: 12px;
                font-weight: 400;
                display: inline-block;
                line-height: 22px;
              }
            }
            .icon {
              width: 34px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              font-size: 12px;
              border-radius: 2px;
              margin-right: 8px;
            }
          }
          .time {
            font-size: 12px;
          }
          .price {
            font-family: "Tahoma";
            font-size: 16px;
            font-weight: 600;
            color: #ff514e;
          }
        }
      }
    }
  }
}

@media (min-width: 750px) {
  .search_page {
    .search {
      width: 900px;
      margin: 20px auto;
      padding: 0;
    }
    .keywords,
    .search_result,
    .result_box {
      width: 900px;
      margin: 0 auto;
    }
  }
}
