.player {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  overflow: hidden;
  padding: 16px 24px 0;

  .title {
    width: 100%;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1d2129;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 750px) {
  .player {
    width: 900px;
    margin: 20px auto;
    padding: 0;
    gap: 24px;
    .title {
      font-size: 24px;
      height: 33px;
      line-height: 33px;
    }
  }
}
