.countdown {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 32px 24px;
  max-width: unset;

  h3,
  p {
    margin: 0;
    padding: 0;
  }

  .title {
    width: 100%;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .subtitle {
    width: 100%;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 22px;
  }

  .countdown-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .time {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 28px;
    }

    .unit {
      width: 14px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      margin: 0 8px;
      color: #86909d;
      &:last-of-type {
        margin-right: 0;
      }
    }

    img {
      width: 141px;
    }

    p {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #86909d;
      line-height: 20px;
      margin-top: 4px;
    }
  }
}

@media (min-width: 760px) {
  .countdown {
    max-width: 900px;

    .title {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 16px;
    }

    .subtitle {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 25px;
    }

    .content {
      .time {
        width: 48px;
        height: 48px;
        border-radius: 5px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 32px;
      }
    }
  }
}
