.banner {
  position: relative;
  width: 900px;
  margin: 0 auto 40px;
  max-height: 900px;
  color: #fff;

  .swiper-pagination-progressbar,
  .swiper-container-horizontal > .swiper-pagination-progressbar,
  .swiper-container-vertical
    > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 120px;
    height: 6px;
    top: 90%;
    left: calc(50% - 60px);
    border-radius: 3px;
  }

  .swiper-pagination-bullet {
    opacity: 1;
    background: #d0d0d0;
  }
  .swiper-pagination-bullet-active {
    background: #2282ff;
  }
  .swiper-pagination-progressbar {
    background: #d0d0d0;
  }

  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    font-size: 12px;
    min-width: 18px;
    width: unset;
    right: 50px;
    left: unset;
    top: unset;
    line-height: 18px;
    bottom: 13px;
    min-height: 18px;
    padding: 0 5px;
    background: rgba(#000000, 0.3);
    border-radius: 100px;
  }

  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #2282ff;
    height: 6px;
    border-radius: 3px;
  }

  .slider {
    width: 100%;
    max-height: 900px;
    margin: 0 auto;
    overflow: hidden;
    a {
      width: 100%;
      max-height: 900px;
      list-style-type: none;
      color: #f4f4f4;
      display: inline-block;
      img {
        width: 100%;
        height: 100%;
        max-height: 900px;
      }
      .box {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
      }
      .subtitle {
        margin-bottom: 15px;
        font-size: 24px;
      }
      .title {
        font-size: 50px;
        margin-bottom: 30px;
      }
      .desc {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 70px;
      }
      .link {
        display: inline-block;
        width: 216px;
        height: 54px;
        font-size: 18px;
        line-height: 54px;
        text-align: center;
        color: #f4f4f4;
        background-color: #0089e3;
        transition: all 0.3s ease-in;
        &:hover {
          background-color: #0081d6;
        }
      }
    }
  }
  .swiper-container-3d {
    width: 100%;
    height: 100%;
    padding-top: 0px;
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
      border-radius: 10px !important;
      background-image: unset;
    }
    a {
      width: 810px;
      border-radius: 10px;
      img {
        width: 810px;
        max-height: 900px;
        border-radius: 10px;
      }
    }
    :global {
      .swiper-slide-shadow-left,
      .swiper-slide-shadow-right {
        border-radius: 10px !important;
      }
    }
  }
  .dots {
    position: absolute;
    bottom: 100px;
    right: 20%;
    li {
      display: inline-block;
      vertical-align: middle;
      width: 14px;
      height: 14px;
      margin-left: 10px;
      background-color: #fff;
      border-radius: 7px;
      cursor: pointer;
      transition: all 0.15s ease-in;
      &.slt,
      &:hover {
        width: 28px;
        background-color: #0197e5;
      }
    }
  }
}

@media (max-width: 750px) {
  .banner {
    max-height: 375px;
    width: 100%;
    margin: 0;
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      font-size: 12px;
      min-width: 18px;
      width: unset;
      right: 25px;
      left: unset;
      top: unset;
      line-height: 18px;
      bottom: 13px;
      min-height: 18px;
      padding: 0 5px;
      background: rgba(#000000, 0.3);
      border-radius: 100px;
    }
    .swiper-pagination-progressbar,
    .swiper-container-horizontal > .swiper-pagination-progressbar,
    .swiper-container-vertical
      > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
      width: 60px;
      height: 3px;
      top: 90%;
      left: calc(50% - 30px);
    }
    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
      background: #2282ff;
      height: 3px;
      border-radius: 3px;
    }
    .slider {
      max-height: 375px;
      height: 100%;
      a {
        img {
          width: 100%;
        }
        .box {
          max-width: 100%;
          width: 100%;
          padding: 0 5%;
          text-align: center;
          user-select: none;
        }
        .subtitle {
          font-size: 14px;
          margin-bottom: 10px;
        }
        .title {
          font-size: 22px;
          margin-bottom: 10px;
        }
        .desc {
          font-size: 12px;
          margin-bottom: 10px;
        }
        .link {
          width: 80px !important;
          height: 20px;
          font-size: 12px;
          line-height: 20px;
          border-radius: 4px;
        }
      }
    }
    .swiper-container-3d {
      a {
        width: calc(100% - 48px);
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
    .dots {
      bottom: 20px;
      right: 10%;
      li {
        width: 7px;
        height: 7px;
      }
    }
  }
}
