.activitySection {
  width: 100%;
  transition: all 0.15s ease-in-out;
  position: relative;
  padding: 16px 24px 0;
  background: #fff;
  li {
    list-style: none;
  }
  .activityList {
    h4 {
      display: flex;
      justify-content: space-between;
      color: #333;
      font-weight: 500;
      font-size: 16px;
      height: 17px;
      line-height: 17px;
      margin-bottom: 16px;
      span:first-of-type {
        display: block;
        max-width: 70%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      span:nth-of-type(2) {
        color: #999;
        font-weight: 400;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-top: -4px;
          margin-left: 2px;
          transform: rotate(90deg);
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .catalogTab {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap !important;
    align-items: center;
    margin: 0 0 10px 0;
    padding: 0 0 4px 0;
    list-style: none;
    li {
      flex-shrink: 0;
      margin-right: 8px;
      padding: 5px 16px;
      font-size: 13px;
      cursor: pointer;
      border-radius: 12px;
      border: 1px solid hsla(0, 0%, 59.2%, 0.25);
      &.active {
        border: 1px solid #175fff;
        color: #175fff;
      }
    }
  }
  .tabList {
    .tabList_tab {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      overflow-x: scroll;
      -ms-overflow-style: none;
      li {
        color: #333;
        font-weight: 500;
        font-size: 16px;
        height: 30px;
        line-height: 20px;
        padding: 4px 0;
        align-self: baseline;
        flex-shrink: 0;
        list-style: none;
      }
      li:last-of-type {
        margin-right: 0 !important;
      }
      .active {
        line-height: 22px;
        color: #2282ff;
        font-size: 16px;
        border-bottom: solid 2px #2282ff;
      }
    }
    .tabList_tab::-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
    }
    .seeMore {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
      margin-top: 8px;
      span {
        color: #999;
        font-weight: 400;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-top: -4px;
          margin-left: 2px;
          transform: rotate(90deg);
        }
      }
    }
  }

  .lists {
    width: 100%;
    text-align: center;
    overflow: hidden;
    margin-bottom: 0;
    .desc {
      position: relative;
      > .uv {
        position: absolute;
        z-index: 1;
        height: 30px;
        font-size: 12px;
        color: #fff;
        line-height: 36px;
        padding: 0 6px 6px 0;
        right: 0;
        top: -30px;
        width: 100%;
        text-align: right;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
      }
    }
    .activities {
      .modal {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        background: rgba(56, 61, 66, 0.87);
        color: #000;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        .special {
          margin: auto;
          color: #fff;
          height: 90px;
          width: 65px;
          overflow: hidden;
          img {
            width: 90%;
            margin-left: 10%;
            margin-top: 0;
          }
          div {
            width: 65px;
            font-size: 12px;
            height: 22px;
            line-height: 22px;
            margin-top: -10px;
          }
        }
      }

      &.vertical {
        max-width: 100%;
        width: 100%;
        display: block;
        margin-bottom: 20px;
        box-shadow: none;
        background-color: #fff;
        .cover {
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center 0;
          border-radius: 4px;
          position: relative;
        }
        .modal {
          .special {
            position: absolute;
            top: calc(50% - 45px);
            left: calc(50% - 32.5px);
          }
        }
        .desc {
          &:before,
          &:after {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
          .left {
            float: left;
            width: 100%;
            > p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-break: break-all;
              text-align: left;
            }
          }
          .ct {
            display: flex;
            justify-content: space-between;
            align-items: center;
            > div {
              display: flex;
              align-items: center;
            }
            .row {
              flex-direction: row-reverse;
              width: calc(100% - 150px);
              justify-content: space-between;
              .uv {
                color: #999;
                font-size: 12px;
              }
            }
          }
          .tt {
            font-size: 14px;
            margin: 10px 0 8px;
            line-height: 20px;
            height: 20px;
            width: 100%;
            overflow: hidden;
            color: #333;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .icon {
            width: 34px;
            height: 16px;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            border-radius: 2px;
          }
          .time {
            font-size: 12px;
            color: #999;
            line-height: 16px;
            margin-left: 8px;
          }
          .price {
            font-size: 16px;
            height: 22px;
            color: #ff514e;
            line-height: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
          }
        }
      }
      &.horizontal {
        margin: 0 0 24px;
        display: block;
        width: 100%;
        height: 90px;
        box-shadow: none;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        position: relative;
        .cover {
          width: 160px;
          height: 90px;
          border-radius: 4px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center 0;
          border-radius: 4px;
          position: absolute;
        }
        .desc {
          max-width: 150px;
          margin-left: 16px;
          position: absolute;
          left: 160px;
          height: 100%;
          text-align: left;
          .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }
          .tt {
            height: 40px;
            margin: 0;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            color: #333;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .ct {
            display: flex;
            flex-direction: column-reverse;
            > div {
              display: flex;
              align-items: center;
            }
            .row {
              height: 22px;
              line-height: 22px;
              margin-bottom: 4px;
              .uv {
                color: #999;
                font-size: 12px;
                font-weight: 400;
                display: inline-block;
                line-height: 22px;
              }
            }
            .icon {
              width: 34px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              font-size: 12px;
              border-radius: 2px;
              margin-right: 8px;
            }
          }
          .time {
            font-size: 12px;
          }
          .price {
            font-family: "Tahoma";
            font-size: 16px;
            font-weight: 600;
            color: #ff514e;
          }
        }
      }

      &.halfLine {
        width: calc(50% - 8px);
        margin-bottom: 24px;
        margin-left: 1px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 0px 4px 0px rgba(131, 146, 158, 0.32);
        .cover {
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center 0;
          border-radius: 4px;
          position: relative;
        }
        .desc {
          text-align: left;
          padding: 8px;
          height: 102px;
          .tt {
            color: #333;
            line-height: 20px;
            overflow: hidden;
            width: 100%;
            font-size: 14px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin-bottom: 4px;
          }
          .left {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .ct {
            display: flex;
            flex-direction: column-reverse;
            > div:nth-of-type(1) {
              display: flex;
              margin-top: 2px;
            }
            .row {
              display: flex;
              height: 22px;
              line-height: 22px;
              align-items: center;
            }
            .icon {
              width: 34px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              font-size: 12px;
              border-radius: 2px;
              margin-right: 6px;
            }
            .time {
              font-size: 12px;
              color: #999;
              line-height: 16px;
            }
            .price {
              font-size: 16px;
              height: 22px;
              color: #ff514e;
              line-height: 22px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
            }
            .uv {
              color: #999;
              font-size: 12px;
            }
          }
        }
      }
      &.halfLine:nth-of-type(odd) {
        margin-right: 12px;
      }

      &.oneLine,
      &.twoLine {
        width: 155px;
        margin-bottom: 16px;
        margin-right: 12px;
        margin-left: 2px;
        background: #fff;
        border-radius: 4px;
        flex-shrink: 0;
        box-shadow: 0px 0px 4px 0px rgba(131, 146, 158, 0.32);
        list-style: none;
        .cover {
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center 0;
          border-radius: 4px;
          position: relative;
        }
        .desc {
          text-align: left;
          padding: 8px;
          height: 102px;
          .tt {
            color: #333;
            line-height: 20px;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin-bottom: 4px;
          }
          .left {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .ct {
            display: flex;
            flex-direction: column-reverse;
            > div:nth-of-type(1) {
              display: flex;
              margin-top: 2px;
            }
            .row {
              display: flex;
              height: 22px;
              line-height: 22px;
              align-items: center;
            }
            .icon {
              width: 34px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              font-size: 12px;
              border-radius: 2px;
              margin-right: 6px;
            }
            .time {
              font-size: 12px;
              color: #999;
              line-height: 16px;
            }
            .price {
              font-size: 16px;
              height: 22px;
              color: #ff514e;
              line-height: 22px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
            }
            .uv {
              color: #999;
              font-size: 12px;
            }
          }
        }
      }
    }

    .card {
      &.halfLine {
        box-shadow: none;
        .desc {
          padding: 8px 0 0;
        }
      }
      &.vertical {
        margin-bottom: 16px;
      }
    }
  }
}

@media (min-width: 760px) {
  .activitySection {
    margin: 0 auto;
    max-width: 900px;
    padding: 0;

    .activityList,
    .tabList {
      h4 {
        font-size: 24px;
        display: flex;
        height: 33px;
        line-height: 33px;
        align-items: center;
        margin-bottom: 24px;
        justify-content: flex-start;
        position: relative;
        span:nth-of-type(2) {
          position: absolute;
          display: inline-block;
          height: 22px;
          line-height: 22px;
          right: 0;
        }
      }
      .tabList_box {
        display: flex;
        justify-content: space-between;
        user-select: none;
        > div {
          width: 64px;
          display: flex;
          justify-content: space-between;
          height: 30px;
          align-items: center;
          img {
            height: 24px;
            width: 24px;
            cursor: pointer;
            border-radius: 12px;
          }
        }
      }
      .tabList_tab {
        cursor: pointer;
        max-width: 750px;
        overflow-x: scroll;
        margin-bottom: 32px;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          width: 0;
          display: none;
        }
        .active {
          font-size: 20px;
          font-weight: 600;
          line-height: 20px;
        }
        li {
          font-size: 20px;
          color: #666;
          font-weight: 600;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 48px;
        }
      }

      .seeMore {
        span {
          font-size: 14px;
        }
      }

      .lists {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 0;

        .modal {
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
          background: rgba(56, 61, 66, 0.87);
          color: #000;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          .special {
            width: 80px;
            position: absolute;
            overflow: hidden;
            /* height: 110px; */
            top: 50% !important;
            left: 50% !important;
            margin: -55px 0 0 -40px;
            display: flex;
            flex-direction: column;
            img {
              width: 80%;
              margin-left: 10%;
            }
            div {
              width: 80px;
              height: 25px;
              line-height: 25px;
              background: #fff;
              border-radius: 4px;
              display: inline-block;
              font-size: 14px;
              color: #333;
            }
          }
        }

        .vertical,
        .oneLine,
        .twoLine {
          width: 434px;
          // margin: 0 1.5%;
          margin-bottom: 38px;
          .desc {
            .row {
              width: calc(100% - 140px);
              display: flex;
              justify-content: space-between;
            }
            .tt {
              font-size: 16px;
              font-weight: 500;
            }
          }

          &:nth-of-type(odd) {
            margin-right: 32px;
          }

          .cover {
            border-radius: 8px;
            &:hover {
              transition: ease-in-out 0.4s;
              box-shadow: 5px 5px 10px 2px rgb(236, 236, 236);
            }
          }
        }

        .halfLine,
        .horizontal {
          display: block;
          margin-bottom: 24px;
          box-shadow: none;
          background-color: #fff;
          height: auto;
          width: 23%;
          border-radius: 4px;
          box-shadow: 0px 4px 9px 0px rgba(210, 215, 222, 0.47);
          margin-left: 0.5%;
          &:hover {
            transition: ease-in-out 0.4s;
            box-shadow: 5px 5px 10px 2px rgb(236, 236, 236);
          }
          &:not(:nth-of-type(4n)) {
            margin-right: 1.7%;
          }
          .cover {
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center 0;
            border-radius: 4px 4px 0 0;
            position: relative;
          }
          .modal {
            .special {
              position: absolute;
              top: calc(50% - 45px);
              left: calc(50% - 32.5px);
            }
          }
          .desc {
            width: 100%;
            left: 0;
            margin-left: 0;
            height: auto;
            position: relative;
            text-align: left;
            padding: 8px 8px 14px;
            max-width: unset;
            &:before,
            &:after {
              content: "";
              display: table;
            }
            &:after {
              clear: both;
            }
            // .left {
            //   > p {
            //     width: 100%;
            //     overflow: hidden;
            //     text-overflow: ellipsis;
            //     white-space: nowrap;
            //     word-break: break-all;
            //     font-size: 15px;
            //     font-weight: 500;
            //   }
            // }
            .ct {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: row;
              > div {
                display: flex;
                align-items: center;
              }
              .row {
                flex-direction: row-reverse;
                margin-bottom: 0;
                > .uv {
                  position: absolute;
                  z-index: 1;
                  height: 30px;
                  font-size: 12px;
                  color: #fff;
                  line-height: 36px;
                  padding: 0 6px 6px 0;
                  right: 0;
                  top: -30px;
                  width: 100%;
                  text-align: right;
                  background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.8) 100%
                  );
                }
              }
            }
            .tt {
              font-size: 15px;
              font-weight: 500;
              margin: 0 0 16px;
              line-height: 20px;
              height: 40px;
              width: 100%;
              color: #333;
              text-align: left;
              display: -webkit-box;
              text-overflow: ellipsis;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .ct {
              .icon {
                width: 34px;
                height: 16px;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                border-radius: 2px;
                margin-right: 0;
              }
            }
            .time {
              font-size: 12px;
              color: #999;
              line-height: 16px;
              margin-left: 8px;
            }
            .price {
              font-size: 16px;
              height: 22px;
              color: #ff514e;
              line-height: 22px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
