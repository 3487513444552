.search {
  width: 100%;
  background: #fff;
  padding: 9px 24px;

  > div {
    width: 100%;
    height: 30px;
    background: #f2f3f5;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    input {
      border: none;
      flex: 1;
      outline: none;
      height: 16px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #414a58;
      line-height: 16px;
      background: transparent;
      &::placeholder {
        color: #86909d;
      }
    }
  }
}

@media (min-width: 750px) {
  .search {
    width: 900px;
    margin: 20px auto;
    padding: 0;
  }
}
