.slogan {
  width: 100%;
  padding: 32px 40px;
  background-color: #fff;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: unset;

  p {
    margin: 0;
    padding: 0;
  }
  > img {
    width: 150px;
    height: 40px;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .logoTip {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 295px;
    height: 45px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #c9cdd4;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .desc {
    white-space: pre-wrap;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 22px;
    margin: 0 0 16px 0;
  }
  .copyright {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 16px;
    margin: 0 0 10px 0;
    color: #86909d;
  }
  .link {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 16px;
    gap: 4px;
    a {
      display: inline-block;
      text-align: center;
      text-decoration: none;
    }
  }
}

@media (min-width: 760px) {
  .slogan {
    max-width: 900px;
    margin: 0 auto;
  }
}
