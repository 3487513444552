html,
body {
  user-select: none;
}

.content {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0 auto;
  line-height: 1;
  color: #333;
  background-color: #fff;
}

.content ul {
  padding: 0;
}

.content p {
  margin: 0;
  padding: 0;
}

.max {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  position: relative;
  height: 72px;
  line-height: 72px;
  padding: 0 calc(50% - 450px);
  background-color: #fff;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -2px 12px 0px rgba(169, 175, 189, 0.5);
}
.header .logo {
  /* position: absolute; */
  top: 50%;
  /* transform: translateY(-50%); */
  max-height: 45px;
  cursor: pointer;
}
.header .logoTip {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  height: 45px;
  font-size: 16px;
  line-height: 45px;
  text-align: center;
  border: 1px solid #ccc;
}
.header nav {
  /* float: left; */
  /* margin-left: 150px; */
  float: right;
  padding-left: 40px;
}
.header .loginBox {
  align-items: center;
}
.header .loginBox button {
  /* position: absolute; */
  right: 0;
  top: 13px;
  height: 34px;
  width: 90px;
  line-height: 34px;
  background: #4da1ff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  padding: 0 15px;
}
.header .loginBox .loginBtn {
  color: #4da1ff;
  margin-right: 10px;
  cursor: pointer;
}
.header .loginBox .pcLoginMenu {
  position: absolute;
  right: -90px;
  top: 13px;
  display: none;
  width: 80px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border-radius: 2px;
  font-size: 14px;
}
.header .loginBox .pcLoginMenu::before {
  content: "";
  position: absolute;
  left: -14px;
  top: 9px;
  height: 0;
  width: 0;
  border-width: 8px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.8) transparent transparent;
}
.header .loginBox .pcLoginMenu:hover {
  color: #56a7f3;
}
.header .userBox {
  display: flex;
  align-items: center;
}
.header .userBox img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  right: 0px;
  top: 10px;
}
.header .userBox .nickname {
  padding-left: 10px;
}
.header .userBox .logout {
  color: #4da1ff;
  cursor: pointer;
  padding-left: 10px;
}

.header nav > a,
.header nav > span {
  margin-left: 30px;
  color: #333;
  font-size: 18px;
}
.header nav > a:hover,
.header nav > span:hover {
  color: #108ee9;
}
.header .menu {
  display: none;
}

.menu_modal {
  display: none;
}

.mobilesNav {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-bottom: solid 10px #f2f2f2;
  flex-direction: start;
  min-height: 120px;
  align-items: center;
}

.mobilesNav li {
  list-style: none;
  text-align: center;
}

.mobilesNav li a {
  cursor: pointer;
  color: rgb(31, 28, 28);
  text-decoration: none;
  transition: #000 0.3s ease;
  outline: none;
}

.mobilesNav li img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin: 15px 0 5px;
}

.mobilesNav li figcaption {
  line-height: 24px;
  font-size: 13px;
  margin-bottom: 10px;
}

.main {
  padding: 30px 0 20px;
  text-align: center;
}

.moreActivity {
  padding: 70px 0 20px;
  width: 900px;
  margin: 0 auto;
}

.moreActivity .tab {
  display: flex;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;
}

.moreActivity .tab > span {
  display: inline-block;
  background: url("./assets/next.png") no-repeat;
  background-size: 100% 100%;
  height: 16px;
  width: 16px;
  transform: rotate(-180deg);
}

.moreActivity .tab .menu {
  padding: 0 24px;
  margin: 0;
}

.moreActivity .tab .menu li {
  list-style: none;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.moreActivity .tab .menuArr {
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none;
}

.moreActivity .tab .menuArr::-webkit-scrollbar {
  width: 0;
  display: none;
}

.moreActivity .tab .menuArr li {
  list-style: none;
  color: #666;
  font-weight: 600;
  font-size: 20px;
  height: 30px;
  line-height: 20px;
  padding: 4px 0;
  margin-right: 24px;
  align-self: baseline;
  flex-shrink: 0;
}

.moreActivity .tab .menuArr .active {
  line-height: 22px;
  color: #2282ff;
  font-size: 20px;
  border-bottom: solid 2px #2282ff;
}

.moreActivity .tabs {
  margin: 0 auto 50px;
}

.moreActivity .mobile .tabs {
  margin: 0 40px 10px 20px;
  padding: 0 0px;
}

.moreActivity .mobile .tabs::-webkit-scrollbar {
  display: none;
}

.moreActivity .mobile .tabs li {
  font-size: 16px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.main h2 {
  font-size: 36px;
  color: #2d2d2d;
  margin-bottom: 40px;
  font-weight: 500;
}
.main .tabs,
.moreActivity .tabs {
  text-align: center;
  margin-bottom: 50px;
  padding: 0 20px;
}
.main .tabs li,
.moreActivity .tabs li {
  display: inline-block;
  vertical-align: bottom;
  padding-bottom: 3px;
  font-size: 20px;
  line-height: 20px;
  color: #2d2d2d;
  margin-right: 40px;
  cursor: pointer;
  transition: all 0.25s ease-in;
}
.main .tabs li.active,
.moreActivity .tabs .active {
  color: #2282ff;
  font-weight: 500;
  font-size: 22px;
}
.main .lists,
.moreActivity .lists {
  max-width: 900px;
  overflow: hidden;
  /* padding: 0 97.5px; */
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.moreActivity .mobile .lists {
  margin: 0;
}

.main .ghost {
  width: 218px;
  height: 45px;
  line-height: 24px;
  background: #fff;
  border: solid 1px #e3e1e1;
  outline: none;
  font-size: 15px;
  color: #5e5e5e;
}

.main .ghost:hover {
  color: #2282ff;
  cursor: pointer;
  border: solid 1px #2282ff;
}

.moreActivity .lists li {
  list-style: none;
}

.main .lists .activities.vertical,
.main .lists .cards.vertical {
  display: inline-block;
  vertical-align: middle;
  width: 49%;
  margin-bottom: 33px;
  text-align: left;
  background: #fff;
  position: relative;
}
.main .lists .activities.vertical:nth-of-type(odd),
.main .lists .cards.vertical:nth-of-type(odd) {
  margin-right: 2%;
}

.main .lists .activities.vertical .cover,
.main .lists .cards.vertical .cover {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
  border-radius: 4px;
  overflow: hidden;
}

.main .lists .activities.vertical .cover .status,
.main .lists .cards.vertical .cover .status {
  position: absolute;
  padding: 0 7px;
  height: 25px;
  font-size: 14px;
  background: #1890ff;
  opacity: 0.8;
  top: 10px;
  right: 15px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  border-radius: 3px;
}

.main .lists .activities.vertical .cover .modal,
.main .lists .cards.vertical .cover .modal {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background: rgba(56, 61, 66, 0.87);
  color: #000;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

.main .lists .activities.vertical .cover .modal .special,
.main .lists .cards.vertical .cover .modal .special {
  width: 80px;
  position: absolute;
  overflow: hidden;
  /* height: 110px; */
  top: 50%;
  left: 50%;
  margin: -55px 0 0 -40px;
  display: flex;
  flex-direction: column;
}

.main .lists .activities.vertical .cover .modal .special img,
.main .lists .cards.vertical .cover .modal .special img {
  width: 80%;
  margin-left: 10%;
}

.main .lists .activities.vertical .cover .modal .special div,
.main .lists .cards.vertical .cover .modal .special div {
  width: 80px;
  height: 25px;
  line-height: 25px;
  background: #fff;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
}

.main .lists .activities.vertical .cover > img,
.main .lists .cards.vertical .cover > img {
  height: 100%;
  width: 100%;
  position: absolute;
}

.main .lists .activities.vertical .desc:before,
.main .lists .activities.vertical .desc:after,
.main .lists .cards.vertical .desc:before,
.main .lists .cards.vertical .desc:after {
  content: "";
  display: table;
}

.main .lists .activities.vertical .desc:after,
.main .lists .cards.vertical .desc:after {
  clear: both;
}

.main .lists .activities.vertical .desc .left,
.main .lists .cards.vertical .desc .left {
  width: 100%;
}

.main .lists .activities.vertical .desc .left > p,
.main .lists .cards.vertical .desc .left > p {
  width: 100%;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  display: block;
}

.main .lists .activities.vertical .desc .tt,
.main .lists .cards.vertical .desc .tt {
  font-size: 14px;
  margin: 8px 0;
  line-height: 20px;
  overflow: hidden;
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main .lists .activities.vertical .desc .icon,
.main .lists .cards.vertical .desc .icon {
  width: 34px;
  height: 18px;
  line-height: 15px;
  border-radius: 2px;
  padding: 1px 2px;
  font-size: 14px;
}

.main .lists .activities.vertical .desc .ct,
.main .lists .cards.vertical .desc .ct {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main .lists .activities.vertical .desc .ct > div:first-of-type,
.main .lists .cards.vertical .desc .ct > div:first-of-type {
  display: flex;
  align-items: center;
}

.main .lists .activities.vertical .desc .time,
.main .lists .cards.vertical .desc .time {
  font-size: 14px;
  color: #999;
  line-height: 16px;
  margin-left: 8px;
}

.main .lists .activities.vertical .desc .row,
.main .lists .cards.vertical .desc .row {
  display: flex;
  align-items: center;
  height: 22px;
  line-height: 22px;
  flex-direction: row-reverse;
}

.main .lists .activities.vertical .desc .row .uv,
.main .lists .cards.vertical .desc .row .uv {
  display: inline-block;
  color: #999;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
}

.main .lists .activities.vertical .desc .price,
.main .lists .cards.vertical .desc .price {
  font-size: 16px;
  height: 22px;
  color: #ff514e;
  line-height: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}

.main .lists .activities.horizontal,
.main .lists .cards.horizontal {
  display: inline-block;
  vertical-align: middle;
  width: 44%;
  height: 0;
  padding-bottom: 13%;
  min-width: 400px;
  margin: 0 2% 3%;
  text-align: left;
  background: #fff;
  position: relative;
}

.main .lists .activities.horizontal:nth-child(odd),
.main .lists .cards.horizontal:nth-child(odd) {
  margin-left: 40px;
}

.main .lists .activities.horizontal:before,
.main .lists .activities.horizontal:after,
.main .lists .cards.horizontal:before,
.main .lists .cards.horizontal:after {
  content: "";
  display: table;
}

.main .lists .activities.horizontal:after,
.main .lists .cards.horizontal:after {
  clear: both;
}

.main .lists .activities.horizontal .cover,
.main .lists .cards.horizontal .cover {
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
  border-radius: 4px 0 0 4px;
  position: absolute;
}

.main .lists .activities.horizontal .cover .status,
.main .lists .cards.horizontal .cover .status {
  position: absolute;
  padding: 0 7px;
  height: 25px;
  font-size: 14px;
  background: #1890ff;
  opacity: 0.8;
  top: 10px;
  right: 15px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  border-radius: 3px;
}

.main .lists .activities.horizontal .cover .modal,
.main .lists .cards.horizontal .cover .modal {
  width: 100%;
  height: 100%;
  background: rgba(56, 61, 66, 0.87);
  color: #000;
  text-align: center;
  position: relative;
}

.main .lists .activities.horizontal .cover .modal .special,
.main .lists .cards.horizontal .cover .modal .special {
  width: 80px;
  margin: 0 auto;
  position: absolute;
  overflow: hidden;
  height: 110px;
  top: 50%;
  left: 50%;
  margin: -55px 0 0 -40px;
  font-size: 14px;
}

.main .lists .activities.horizontal .cover .modal .special img,
.main .lists .cards.horizontal .cover .modal .special img {
  width: 80%;
}

.main .lists .activities.horizontal .cover .modal .special div,
.main .lists .cards.horizontal .cover .modal .special div {
  width: 80px;
  height: 25px;
  line-height: 25px;
  background: #fff;
  border-radius: 4px;
  margin-top: -7px;
}

.main .lists .activities.horizontal .desc,
.main .lists .cards.horizontal .desc {
  position: absolute;
  width: 46%;
  height: 100%;
  background: #fff;
  right: 0;
}

.main .lists .activities.horizontal .desc .left,
.main .lists .cards.horizontal .desc .left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.main .lists .activities.horizontal .desc .tt,
.main .lists .cards.horizontal .desc .tt {
  height: 70px;
  overflow: hidden;
  font-size: 18px;
  line-height: 24px;
  color: #454444;
  font-weight: 500;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.main .lists .activities.horizontal .desc .ct,
.main .lists .cards.horizontal .desc .ct {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: calc(100% - 90px);
  width: 100%;
}

.main .lists .activities.horizontal .desc .ct > div:first-of-type,
.main .lists .cards.horizontal .desc .ct > div:first-of-type {
  display: flex;
  align-items: center;
}

.main .lists .activities.horizontal .desc .ct > div:first-of-type .icon,
.main .lists .cards.horizontal .desc .ct > div:first-of-type .icon {
  padding: 1px 2px;
}

.main .lists .activities.horizontal .desc .time,
.main .lists .cards.horizontal .desc .time {
  margin-left: 8px;
  font-size: 12px;
  color: #a1a1a1;
}

.main .lists .activities.horizontal .desc .row,
.main .lists .cards.horizontal .desc .row {
  display: flex;
  align-items: center;
  height: 22px;
  line-height: 22px;
  margin-bottom: 8px;
}

.main .lists .activities.horizontal .desc .row .uv,
.main .lists .cards.horizontal .desc .row .uv {
  display: inline-block;
  color: #999;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
}

.main .lists .activities.horizontal .desc .price,
.main .lists .cards.horizontal .desc .price {
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 18px;
  color: #d90000;
  font-family: "Tahoma";
  font-weight: 400;
}

.footer {
  background-color: #fff;
  text-align: center;
  color: #fff;
  font-size: 10px;
  padding: 10px 0;
  width: 100%;
}
.footer a {
  color: #ccc;
}

.hide {
  display: none;
}

@media (max-width: 540px) {
  body {
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  .header {
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
    width: 100%;
    left: unset;
  }
  .header .max {
    justify-content: space-between;
  }
  .header .logo {
    min-width: 75px;
    max-height: 20px;
  }
  .header nav {
    display: none;
  }
  .header .menu {
    float: right;
    display: block;
    /* margin-top: 14px; */
    width: 20px;
    height: 14px;
  }

  .menu_modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    background-color: rgba(20, 20, 20, 0.96);
    z-index: 11;
    overflow-y: hidden;
    transition: all 0.15s ease-in;
  }

  .menu_modal .menu_modal_container {
    height: 100%;
    width: 75%;
  }

  .menu_modal .loginBox button {
    width: 162px;
    height: 40px;
    background: #4da1ff;
    font-size: 16px;
    text-align: center;
    color: #fff;
    border-radius: 2px;
    border: none;
    margin: 20px 0px 40px calc(50% - 81px);
  }

  .menu_modal .logOut {
    position: absolute;
    margin: 0;
    width: 100%;
    height: 40px;
    background: #4da1ff;
    font-size: 16px;
    border: none;
    border-radius: 2px;
    bottom: 50px;
    display: none;
    color: #fff;
  }

  .menu_modal .loginBox > div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .menu_modal .loginBox > div img {
    height: 42px;
    width: 42px;
    border-radius: 50%;
  }
  .menu_modal .loginBox > div span {
    color: rgba(225, 225, 225, 0.75);
    font-size: 16px;
    margin-left: 20px;
  }

  .menu_modal > div {
    position: absolute;
    left: 12.5%;
  }

  .menu_modal .close,
  .menu_modal > div a,
  .menu_modal > div p {
    display: block;
    font-size: 14px;
    /* margin: 16px auto; */
    width: 100%;
    line-height: 55px;
    color: rgba(255, 255, 255, 0.75);
    height: 55px;
    border-bottom: solid 1px #333;
  }
  .menu_modal .close {
    font-size: 20px;
    text-align: right;
    cursor: pointer;
  }

  .moreActivity {
    margin-top: 0;
    width: 100%;
  }

  .moreActivity .tab {
    margin-bottom: 0;
    width: 100%;
    padding: 0 24px;
    margin-bottom: 10px;
  }

  .moreActivity .tab > span {
    min-width: 16px;
  }

  .moreActivity .tab .menu {
    margin-bottom: 0;
    justify-content: flex-start;
  }

  .moreActivity .tab .menu li {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    text-align: left;
  }

  .moreActivity .tab .menuArr {
    margin-bottom: 0;
    justify-content: flex-start;
    overflow-x: scroll;
    padding: 0 12px;
  }

  .moreActivity .tab .menuArr li {
    font-size: 16px;
  }

  .moreActivity .tab .menuArr .active {
    font-size: 16px;
  }

  .main {
    padding: 0px 0 10px;
    background-color: #fff;
  }
  .main h2 {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
    margin-left: 24px;
    font-weight: 500;
    border-left: 4px solid #2282ff;
    padding-left: 10px;
  }
  .main .tabs {
    text-align: left;
    margin: 10px;
    padding: 25px 20px 0px;
    margin-bottom: 0px;
    width: calc(100vw - 20px);
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
  }

  .main .tabs::-webkit-scrollbar {
    display: none;
  }

  .main .tabs li {
    font-size: 16px;
    margin-right: 15px;
    margin-bottom: 10px;
    color: #6c6c6c;
  }
  .main .tabs li.active {
    font-size: 18px;
    font-weight: 500;
    color: #373737;
  }
  .main .lists {
    padding: 10px 24px;
  }

  .main .list > div {
    padding-top: 35px;
  }
  .main .lists .activities.vertical {
    margin: 0 0 25px;
    display: block;
    max-width: 100%;
    width: 100%;
    box-shadow: none;
    background-color: #fff;
  }
  .main .lists .activities.vertical .desc .tt {
    line-height: 25px;
  }
  .main .lists .activities.horizontal {
    margin: 0 0 24px;
    display: block;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: 90px;
    box-shadow: none;
    background-color: #fff;
    position: relative;
  }

  .main .lists .activities.horizontal:nth-child(odd),
  .main .lists .cards.horizontal:nth-child(odd) {
    margin-left: 0px;
  }

  .main .lists .activities.horizontal .cover {
    width: 160px;
    border-radius: 4px;
  }

  .main .lists .activities.horizontal .cover .status {
    width: 62px;
    font-size: 12px;
    top: 5px;
    right: 5px;
    line-height: 20px;
    height: 20px;
  }

  .main .lists .activities.horizontal .cover .modal .special {
    margin: -45px 0 0 -32.5px;
    height: 90px;
    width: 65px;
    overflow: hidden;
  }

  .main .lists .activities.horizontal .cover .modal .special img {
    width: 90%;
    margin-top: 0;
  }

  .main .lists .activities.horizontal .cover .modal .special div {
    width: 65px;
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    margin-top: -10px;
  }

  .main .lists .activities.horizontal .desc {
    width: 150px;
    height: 100%;
    padding: 0px;
  }
  .main .lists .activities.horizontal .desc .left {
    height: 100%;
    width: 100%;
  }
  .main .lists .activities.horizontal .desc .ct {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100% - 48px);
  }
  .main .lists .activities.horizontal .desc .ct .row {
    margin-bottom: 0;
  }
  .main .lists .activities.horizontal .desc .ct > div:first-of-type {
    display: flex;
    align-items: center;
  }

  .main .lists .activities.horizontal .desc .ct > div:first-of-type .icon {
    padding: 0px 0px;
    height: 17px;
    text-align: center;
    line-height: 17px;
    border-radius: 2px;
    font-size: 12px;
    width: 32px;
  }

  .main .lists .activities.horizontal .desc .tt {
    display: -webkit-box;
    font-size: 14px;
    width: 150px;
    height: 40px;
    white-space: normal;
    color: #333;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-top: 0px;
  }
  .main .lists .activities.horizontal .desc .price {
    font-size: 16px;
    height: 22px;
    color: #ff514e;
    line-height: 22px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    position: static;
  }
  .main .lists .cards.vertical {
    margin: 0 2% 0;
    display: inline-block;
    max-width: 46%;
    min-width: 46%;
    width: 46%;
    box-shadow: none;
    background-color: #fff;
  }
  .main .lists .cards.vertical .cover {
    position: relative;
    padding-bottom: 56%;
    width: 100%;
    border-radius: 4px 4px 0 0;
  }

  .main .lists .cards.vertical .cover img {
    position: absolute;
    height: 100%;
  }

  .main .lists .activities.horizontal .desc,
  .main .lists .cards.horizontal .desc {
    left: 176px;
  }

  .main .lists .cards.vertical .desc {
    max-height: 42px;
    line-height: 20px;
    color: #333;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .main .lists .cards.vertical .desc .tt {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    height: 41px;
    width: 100%;
  }
  .main .lists .cards.horizontal {
    margin: 0 0 10px;
    display: block;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: 90px;
    box-shadow: none;
    background-color: #fff;
  }
  .main .lists .cards.horizontal .cover {
    width: 160px;
    border-radius: 4px;
  }
  .main .lists .cards.horizontal .desc {
    width: 150px;
  }
  .main .lists .cards.horizontal .desc .tt {
    font-size: 15px;
  }
  .main .lists .cards.horizontal .desc .time {
    font-size: 12px;
  }
  .main .lists .cards.horizontal .desc .price {
    font-size: 13px;
  }
}

.backTop {
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 50px;
  right: 20px;
  overflow: hidden;
  text-align: center;
  border: solid 1px #dbdbdb;
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: space-around;
  transition: ease-in-out 0.3s;
}
.backTop::before {
  line-height: 50px;
  width: 0;
  content: "";
  transition: ease-in-out 0.3s;
}
.backTop:hover::before {
  margin-left: 5px;
  color: #fff;
  content: "回到顶部";
  width: 85px;
}
.backTop:hover {
  cursor: pointer;
  border: solid 1px #2282ff;
  transition: ease-in-out 0.3s;
  width: 135px;
  background: #2282ff;
  color: #fff;
}
.backTop Img {
  width: 50px;
  color: #fff;
}

@media (any-hover: hover) {
  .main .lists li:hover {
    transition: ease-in-out 0.4s;
    transform: scale(1.035);
    box-shadow: 5px 5px 10px 2px rgb(236, 236, 236);
  }

  .main .ghost:hover {
    color: #2282ff;
    cursor: pointer;
    border: solid 1px #2282ff;
  }

  .main .tabs li:hover {
    color: #2282ff;
    font-weight: 500;
    font-size: 22px;
  }
}
