* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  font-family: sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}
