@font-face {
  font-family: "iconfont"; /* Project id 2767045 */
  src: url("//at.alicdn.com/t/font_2767045_yidy84vp03g.woff2?t=1638770943575")
      format("woff2"),
    url("//at.alicdn.com/t/font_2767045_yidy84vp03g.woff?t=1638770943575")
      format("woff"),
    url("//at.alicdn.com/t/font_2767045_yidy84vp03g.ttf?t=1638770943575")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.moreActivity {
  .lists_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 24px 0;
    place-self: start;
  }
  li {
    list-style: none;
  }
  .lists {
    width: 100%;
    text-align: center;
    overflow: hidden;
    .desc {
      position: relative;
      > .uv {
        position: absolute;
        z-index: 1;
        height: 30px;
        font-size: 12px;
        color: #fff;
        line-height: 36px;
        padding: 0 6px 6px 0;
        right: 0;
        top: -30px;
        width: 100%;
        text-align: right;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
      }
    }
    .activities {
      .modal {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        background: rgba(56, 61, 66, 0.87);
        color: #000;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        .special {
          margin: auto;
          color: #fff;
          height: 90px;
          width: 65px;
          overflow: hidden;
          img {
            width: 90%;
            margin-left: 10%;
            margin-top: 0;
          }
          div {
            width: 65px;
            font-size: 12px;
            height: 22px;
            line-height: 22px;
            margin-top: -10px;
          }
        }
      }

      &.vertical {
        display: block;
        margin-bottom: 33px;
        max-width: 100%;
        width: 100%;
        box-shadow: none;
        background-color: #fff;
        .cover {
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center 0;
          border-radius: 4px;
          position: relative;
        }
        .modal {
          .special {
            position: absolute;
            top: calc(50% - 45px);
            left: calc(50% - 32.5px);
          }
        }
        .desc {
          &:before,
          &:after {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
          .left {
            float: left;
            width: 100%;
            > p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-break: break-all;
            }
          }
          .ct {
            display: flex;
            justify-content: space-between;
            align-items: center;
            > div {
              display: flex;
              align-items: center;
            }
            .row {
              flex-direction: row-reverse;
              width: calc(100% - 150px);
              justify-content: space-between;
              .uv {
                color: #999;
                font-size: 12px;
              }
            }
          }
          .tt {
            font-size: 14px;
            margin: 10px 0 8px;
            line-height: 20px;
            height: 20px;
            width: 100%;
            overflow: hidden;
            color: #333;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .icon {
            width: 34px;
            height: 16px;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            border-radius: 2px;
          }
          .time {
            font-size: 12px;
            color: #999;
            line-height: 16px;
            margin-left: 8px;
          }
          .price {
            font-size: 16px;
            height: 22px;
            color: #ff514e;
            line-height: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
          }
        }
      }
      &.horizontal {
        margin: 0 0 24px;
        display: block;
        width: 100%;
        height: 90px;
        box-shadow: none;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        position: relative;
        .cover {
          width: 160px;
          height: 90px;
          border-radius: 4px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center 0;
          border-radius: 4px;
          position: absolute;
        }
        .desc {
          width: 150px;
          margin-left: 16px;
          position: absolute;
          left: 160px;
          height: 100%;
          text-align: left;
          .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }
          .tt {
            font-size: 14px;
            height: 40px;
            font-size: 14px;
            color: #333;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .ct {
            display: flex;
            flex-direction: column-reverse;
            > div {
              display: flex;
              align-items: center;
            }
            .row {
              height: 22px;
              line-height: 22px;
              margin-bottom: 4px;
              .uv {
                color: #999;
                font-size: 12px;
                font-weight: 400;
                display: inline-block;
                line-height: 22px;
              }
            }
            .icon {
              width: 34px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              font-size: 12px;
              border-radius: 2px;
              margin-right: 8px;
            }
          }
          .time {
            font-size: 13px;
          }
          .price {
            font-family: "Tahoma";
            font-size: 16px;
            font-weight: 600;
            color: #ff514e;
          }
        }
      }

      &.halfLine {
        width: calc(50% - 8px);
        margin-bottom: 24px;
        margin-left: 1px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 0px 4px 0px rgba(131, 146, 158, 0.32);
        .cover {
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center 0;
          border-radius: 4px;
          position: relative;
        }
        .desc {
          text-align: left;
          padding: 8px;
          height: 102px;
          .tt {
            color: #333;
            line-height: 20px;
            overflow: hidden;
            width: 100%;
            font-size: 14px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin-bottom: 4px;
          }
          .left {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .ct {
            display: flex;
            flex-direction: column-reverse;
            > div:nth-of-type(1) {
              display: flex;
              margin-top: 2px;
            }
            .row {
              display: flex;
              height: 22px;
              line-height: 22px;
              align-items: center;
            }
            .icon {
              width: 34px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              font-size: 12px;
              border-radius: 2px;
              margin-right: 6px;
            }
            .time {
              font-size: 12px;
              color: #999;
              line-height: 16px;
            }
            .price {
              font-size: 16px;
              height: 22px;
              color: #ff514e;
              line-height: 22px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
            }
            .uv {
              color: #999;
              font-size: 12px;
            }
          }
        }
      }
      &.halfLine:nth-of-type(odd) {
        margin-right: 12px;
      }

      &.oneLine,
      &.twoLine {
        width: 155px;
        margin-bottom: 16px;
        margin-right: 0;
        margin-left: 2px;
        background: #fff;
        border-radius: 4px;
        flex-shrink: 0;
        box-shadow: 0px 0px 4px 0px rgba(131, 146, 158, 0.32);
        list-style: none;
        &:not(:nth-of-type(2n)) {
          margin-right: 12px;
        }
        .cover {
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center 0;
          border-radius: 4px;
          position: relative;
        }
        .desc {
          text-align: left;
          padding: 8px;
          height: 102px;
          .tt {
            color: #333;
            line-height: 20px;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin-bottom: 4px;
          }
          .left {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .ct {
            display: flex;
            flex-direction: column-reverse;
            > div:nth-of-type(1) {
              display: flex;
              margin-top: 2px;
            }
            .row {
              display: flex;
              height: 22px;
              line-height: 22px;
              align-items: center;
            }
            .icon {
              width: 34px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              font-size: 12px;
              border-radius: 2px;
              margin-right: 6px;
            }
            .time {
              font-size: 12px;
              color: #999;
              line-height: 16px;
            }
            .price {
              font-size: 16px;
              height: 22px;
              color: #ff514e;
              line-height: 22px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
            }
            .uv {
              color: #999;
              font-size: 12px;
            }
          }
        }
      }
    }

    .card {
      &.halfLine {
        box-shadow: none;
        .desc {
          padding: 8px 0 0;
        }
      }
      &.vertical {
        margin-bottom: 16px;
      }
    }
  }
}
.graphicwrap {
  position: relative;
  width: 900px;
  font-size: 16px;
  background-color: #fff;
  &_richTextBox {
    width: 100%;
    background-color: #fff;
    &__simditorbody {
      min-height: 100px;
      padding: 0;
      margin: 0;
      p {
        min-height: 10px;
        line-height: 1.75;
        word-wrap: break-word;
      }
      img {
        max-width: 100%;
      }
    }
  }
}
:global {
  .simditor {
    border: none;
  }
}

.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 50px;
  right: 38px;
  width: 44px;
  height: 100px;

  &__qrCode,
  &__sideTop {
    width: 44px;
    height: 44px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__qrCode {
    margin-bottom: 16px;
    &:hover {
      cursor: pointer;

      .sidebar__qrCode__wrap {
        display: block !important;
      }
    }
    &__wrap {
      display: none !important;
      display: block;
      position: absolute;
      left: -168px;
      bottom: 53px;
      justify-content: center;
      width: 150px;
      height: 180px;
      text-align: center;
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      img {
        width: 120px;
        height: 120px;
        margin-top: 18px;
      }
      p {
        color: rgba(102, 102, 102, 0.85);
        font-size: 12px;
        font-weight: 400;
        padding-top: 10px;
      }
      &::after {
        position: absolute;
        content: "";
        top: 148px;
        right: -16px;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-left: 8px solid #fff;
      }
    }
  }
  &__sideTop {
    &:hover {
      cursor: pointer;
      .sidebar__sideTop__text {
        display: flex !important;
      }
    }

    &__text {
      display: none !important;
      position: absolute;
      left: -130px;
      bottom: 4px;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 36px;
      background: #ffffff;
      border-radius: 3px;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      span {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 500;
      }
      &::after {
        position: absolute;
        content: "";
        top: 10px;
        right: -16px;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-left: 8px solid #fff;
      }
    }
  }
}
.sudokuNav {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-bottom: solid 10px #f2f2f2;
  flex-direction: start;
  min-height: 120px;
  align-items: center;
  li {
    list-style: none;
    text-align: center;
    a {
      cursor: pointer;
      color: rgb(31, 28, 28);
      text-decoration: none;
      transition: #000 0.3s ease;
      outline: none;
    }
    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      overflow: hidden;
      margin: 15px 0 5px;
    }
    figcaption {
      line-height: 24px;
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
}
.elevatorSection {
  width: 100%;
  transition: all 0.15s ease-in-out;
  position: relative;
  padding: 0 24px 0;
  background: #fff;
  margin-bottom: 10px;
  .ant-anchor-wrapper {
    .ant-anchor {
      width: 100%;
      background: #fff;
      overflow-x: scroll;
      border-bottom: 1px solid #eeeeee;
      -ms-overflow-style: none;
      .ant-anchor-link {
        display: block;
        margin-right: 25px;
        .ant-anchor-link-title {
          color: #333;
        }
        color: #333;
        font-weight: 500;
        font-size: 14px;
        height: 30px;
        line-height: 20px;
        padding: 20px 0 36px;
        align-self: baseline;
        flex-shrink: 0;
        list-style: none;
      }
      .ant-anchor:last-of-type {
        margin-right: 0 !important;
      }
      .active {
        color: #2282ff;
        font-size: 16px !important;
        border-bottom: solid 2px #2282ff;
        .ant-anchor-link-title {
          color: #2282ff;
        }
      }
      .special_active {
        line-height: 22px;
        color: #e5123c;
        font-size: 16px !important;
        border-bottom: solid 2px #e5123c;
        .ant-anchor-link-title {
          color: #e5123c;
        }
      }
    }
    .ant-anchor::-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
    }
  }
}
@media (min-width: 760px) {
  .moreActivity {
    .lists {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }
      .lists_box {
        padding: 0;
        place-self: flex-start;
        min-width: 900px;
        max-width: 900px;
      }
      .modal {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        background: rgba(56, 61, 66, 0.87);
        color: #000;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        .special {
          width: 80px;
          position: absolute;
          overflow: hidden;
          /* height: 110px; */
          top: 50% !important;
          left: 50% !important;
          margin: -55px 0 0 -40px;
          display: flex;
          flex-direction: column;
          img {
            width: 80%;
            margin-left: 10%;
          }
          div {
            width: 80px;
            height: 25px;
            line-height: 25px;
            background: #fff;
            border-radius: 4px;
            display: inline-block;
            font-size: 14px;
            color: #333;
          }
        }
      }

      .activities.vertical,
      .activities.oneLine,
      .activities.twoLine {
        width: 434px;
        // margin: 0 1.5%;
        margin-bottom: 38px;
        .desc {
          .row {
            width: calc(100% - 140px);
            display: flex;
            justify-content: space-between;
          }
        }

        .desc {
          .tt {
            font-size: 16px;
            font-weight: 500;
          }
        }

        &:nth-of-type(odd) {
          margin-right: 32px;
        }

        .cover {
          border-radius: 8px;
          &:hover {
            transition: ease-in-out 0.4s;
            box-shadow: 5px 5px 10px 2px rgb(236, 236, 236);
          }
        }
      }

      .activities.halfLine,
      .activities.horizontal {
        display: block;
        margin-bottom: 24px;
        background-color: #fff;
        height: auto;
        width: 23%;
        border-radius: 4px;
        box-shadow: 0px 4px 9px 0px rgba(210, 215, 222, 0.47);
        margin-left: 0.5%;
        &:hover {
          transition: ease-in-out 0.4s;
          box-shadow: 5px 5px 10px 2px rgb(236, 236, 236);
        }
        &:not(:nth-of-type(4n)) {
          margin-right: 1.7%;
        }
        .cover {
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center 0;
          border-radius: 4px 4px 0 0;
          position: relative;
        }
        .modal {
          .special {
            position: absolute;
            top: calc(50% - 45px) !important;
            left: calc(50% - 32.5px) !important;
          }
        }
        .desc {
          width: 100%;
          left: 0;
          margin-left: 0;
          height: auto;
          position: relative;
          text-align: left;
          padding: 8px 8px 14px;
          &:before,
          &:after {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
          .ct {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            > div {
              display: flex;
              align-items: center;
            }
            .row {
              flex-direction: row-reverse;
              margin-bottom: 0;
              > .uv {
                position: absolute;
                z-index: 1;
                height: 30px;
                font-size: 12px;
                color: #fff;
                line-height: 36px;
                padding: 0 6px 6px 0;
                right: 0;
                top: -30px;
                width: 100%;
                text-align: right;
                background: linear-gradient(
                  180deg,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0.8) 100%
                );
              }
            }
          }
          .tt {
            font-size: 15px;
            font-weight: 500;
            margin: 0 0 16px;
            line-height: 20px;
            height: 40px;
            width: 100%;
            color: #333;
            text-align: left;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .ct {
            .icon {
              width: 34px;
              height: 16px;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
              border-radius: 2px;
              margin-right: 0;
            }
          }
          .time {
            font-size: 12px;
            color: #999;
            line-height: 16px;
            margin-left: 8px;
          }
          .price {
            font-size: 16px;
            height: 22px;
            color: #ff514e;
            line-height: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
          }
        }
      }

      .activities.halfLine,
      .activities.oneLine,
      .activities.twoLine {
        .desc {
          position: relative;
          > .uv {
            position: absolute;
            z-index: 1;
            height: 30px;
            font-size: 12px;
            color: #fff;
            line-height: 36px;
            padding: 0 6px 6px 0;
            right: 0;
            top: -30px;
            width: 100%;
            text-align: right;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.8) 100%
            );
          }
        }
      }
    }
  }
  .sudokuNav {
    max-width: 900px;
    margin: 0 auto;
    li {
      img {
        height: 100px;
        width: 100px;
        margin: 30px 0 10px;
      }
      figcaption {
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
  .elevatorSection {
    width: 100%;
    transition: all 0.15s ease-in-out;
    position: relative;
    padding: 16px 24px 0;
    background: #fff;
    margin: 0 auto;
    max-width: 900px;
    padding: 0;
    margin-bottom: 30px;
    .ant-anchor-wrapper {
      .ant-anchor {
        width: 100%;
        background: #fff;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        overflow-x: scroll;
        border-bottom: 1px solid #eeeeee;
        -ms-overflow-style: none;
        .ant-anchor-link {
          display: block;
          margin-right: 70px;
          .ant-anchor-link-title {
            color: #333;
          }
          color: #333;
          font-weight: 500;
          font-size: 18px;
          height: 30px;
          line-height: 20px;
          padding-top: 20px;
          padding-bottom: 36px;
          align-self: baseline;
          flex-shrink: 0;
          list-style: none;
        }
        .ant-anchor:last-of-type {
          margin-right: 0 !important;
        }
        .active {
          color: #2282ff;
          font-size: 20px !important;
          border-bottom: solid 2px #2282ff;
          .ant-anchor-link-title {
            color: #2282ff;
          }
        }
        .special_active {
          line-height: 22px;
          color: #e5123c;
          font-size: 20px !important;
          border-bottom: solid 2px #e5123c;
          .ant-anchor-link-title {
            color: #e5123c;
          }
        }
      }
      .ant-anchor::-webkit-scrollbar {
        display: none;
        height: 0;
        width: 0;
      }
    }
    // }
  }
}

@media (max-width: 750px) {
  .graphicwrap {
    position: relative;
    width: 100%;
    padding: 20px;
    font-size: 16px;
    background-color: #fff;
    &_richTextBox {
      background-color: #fff;
      &__simditorbody {
        width: 100%;
        min-height: auto;
        padding: 0;
        margin: 0;
        p {
          min-height: 10px;
          line-height: 1.75;
          word-wrap: break-word;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  :global {
    .simditor {
      border: none;
    }
  }
}
.bot_bar {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid hsla(0, 0%, 59.2%, 0.25);
  z-index: 100;
  width: 100%;
  height: 60px;

  div {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #999b9d;
    font-weight: 400;
    i {
      display: block;
      font-size: 24px;
    }
    em {
      display: none;
    }
    a {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: #999b9d;
      i {
        display: block;
        font-size: 24px;
      }
      .icon_tab_home_selected {
        font-size: 24px;
      }
      .icon_tab_mine_selected {
        font-size: 24px;
      }
      .text_change {
        margin-top: 3px;
        color: #999b9d;
        font-size: 12px;
      }
    }
    &:hover {
      color: #2282ff;
      cursor: pointer;
      i {
        display: none;
      }
      em {
        display: block;
        color: #2282ff;
      }
      a {
        color: #2282ff;
      }
      .text_change {
        color: #2282ff;
      }
    }
  }
  .tabs_link {
    i {
      display: none;
    }
    em {
      display: block;
      color: #2282ff;
    }
    .text_change {
      color: #2282ff;
    }
  }
}
