.brand {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 14px 0 24px;
  background: #fff;
  p {
    margin: 0;
    padding: 0;
  }
  > img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 16px;
  }

  > div {
    flex: 1;
    .name {
      width: 100%;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1d2129;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .detail {
      margin-top: 6px;
      .value {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2282ff;
        line-height: 20px;
        margin-right: 4px;
      }
      .attribute {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #414a58;
        line-height: 20px;
        margin-right: 16px;
      }
    }
  }

  > button {
    width: 76px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid #2282ff;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2282ff;
    line-height: 20px;
    cursor: not-allowed;
  }
}

@media (min-width: 760px) {
  .brand {
    max-width: 900px;
    margin: 0 auto;
    padding: 0;
  }
}
