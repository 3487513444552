.kf {
  position: fixed;
  bottom: 150px;
  right: 20px;
  z-index: 1;
  p {
    margin: 0;
    padding: 0;
  }
}

.kf-modal {
  p {
    margin: 0;
    padding: 0;
  }
  .ant-modal-content {
    background: #ffffff;
    border-radius: 8px;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1d2129;
      line-height: 22px;
      margin-bottom: 16px;
    }
    .qrcode {
      width: 140px;
      height: 140px;
      margin-bottom: 12px;
    }
    .desc {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #86909d;
      line-height: 20px;
      margin-bottom: 12px;
    }
    .tel {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2282ff;
      line-height: 20px;
      user-select: text;
      img {
        width: 14px;
        margin-right: 6px;
      }
    }
  }
}
